<template>
  <section class="section">
    <form class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" />

      <h3 class="title is-3">
        <router-link to="/admin/gerenciar-facilidades" class="has-text-grey">
          Gerenciamento de Facilidades
        </router-link>
        >
        <span class="has-text-primary">
          {{ isNew ? 'Cadastrar' : 'Editar' }}
        </span>
      </h3>

      <div class="columns is-multiline">
        <b-field label="Categoria" class="column is-half mb-0">
          <b-select v-model="form.facilidadeCategoriaId" name="facilidade-categoria" placeholder="Selecione" expanded required>
            <option v-for="categoria in facilidadeCategorias" :key="categoria.id" :value="categoria.id">
              {{ categoria.nome }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Nome" class="column is-half mb-0">
          <b-input type="text" v-model="form.nome" placeholder="Digite" />
        </b-field>
      </div>
      <b-button type="is-primary" tag="button" native-type="submit" label="Salvar" expanded />
    </form>
  </section>
</template>

<script>
import facilidadeService from '@/services/facilidadeService';
import facilidadeCategoriaService from '@/services/facilidadeCategoriaService';

export default {
  name: 'Edit',
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {},
      facilidadeCategorias: [],
    };
  },
  computed: {
    isNew() {
      return !this.id;
    },
  },
  async created() {
    await this.getFacilidadeCategorias();

    if (!this.isNew) {
      this.isLoading = true;
      facilidadeService
        .getById(this.id)
        .then(({ data }) => {
          this.form = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    async getFacilidadeCategorias() {
      return facilidadeCategoriaService.get().then(({ data }) => {
        this.facilidadeCategorias = data;
      });
    },
    async handleSubmit(event) {
      event.preventDefault();

      this.isLoading = true;

      try {
        if (this.isNew) {
          await facilidadeService.post(this.form).then(() => {
            this.$buefy.snackbar.open('Facilidade cadastrada com sucesso');
            this.$router.push('/admin/gerenciar-facilidades');
          });
        } else {
          await facilidadeService.put(this.form).then(() => {
            this.$buefy.snackbar.open('Facilidade editada com sucesso');
            this.$router.push('/admin/gerenciar-facilidades');
          });
        }
      } catch {
        const action = this.isNew ? 'cadastrar' : 'editar';

        this.$buefy.snackbar.open({
          message: `Erro ao ${action} facilidade`,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
